import { memo } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { isEmail } from 'shared/lib/lodash/lodash';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

type PropertyCardRowProps = {
    name: string;
    value: string | number;
    minimized?: boolean;
    bordered?: boolean;
};

export const PropertyCardRow = memo(
    ({ name, value = '', minimized, bordered }: PropertyCardRowProps) => {
        const output = isPossiblePhoneNumber(value.toString() as string, 'US')
            ? parsePhoneNumber(
                  value.toString() as string,
                  'US',
              ).formatNational()
            : value;

        return (
            <Box
                p={!minimized ? '15px 0 17px' : '6px 0 7px'}
                display="flex"
                borderBottom={bordered ? '1px solid' : undefined}
                borderColor="divider"
                justifyContent="space-between"
                data-testclass={`attribute`}
            >
                <Typography
                    typography={
                        minimized ? 'openSans.body2' : 'openSans.subtitle1'
                    }
                    color="text.primary"
                    data-testclass="label"
                >
                    {name}
                </Typography>
                <Typography
                    component={isEmail(output as string) ? Link : null}
                    maxWidth="340px"
                    color={
                        isEmail(output as string)
                            ? 'blue.500'
                            : 'text.secondary'
                    }
                    href={isEmail(output as string) ? `mailto:${output}` : null}
                    rel="noopener noreferrer"
                    typography={
                        minimized ? 'openSans.body2' : 'openSans.subtitle1'
                    }
                    borderColor="blue.500"
                    textAlign="right"
                    data-testclass="value"
                >
                    {output}
                </Typography>
            </Box>
        );
    },
);
