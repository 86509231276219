import { Box, Theme, Typography, alpha } from '@mui/material';

import { getPrice } from 'shared/lib/getPrice/getPrice';
import { getRouteGlobalCatalog } from 'shared/const/router';
import { shouldShowUOM } from 'shared/lib/unitOfMeasureUtility/unitOfMeasureUtility';
import { useMatch } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface CatalogItemPriceProps {
    price: number;
    total?: number;
    uom?: string;
}

export const CatalogItemPrice = ({
    price = 0,
    total,
    uom = 'EA',
}: CatalogItemPriceProps) => {
    const { t } = useTranslation();

    const isGlobalCatalog = useMatch(
        getRouteGlobalCatalog(':propertyId') + '/*',
    );

    const styles = useMemo(
        () => ({
            fontSize: '12px',
            letterSpacing: '0.15px',
            color: (theme: Theme) => alpha(theme.palette.common.black, 0.5),
        }),
        [],
    );

    const itemPrice = useMemo(() => getPrice(price), [price]);

    return (
        <Box display="flex" justifyContent="space-between" p="12px 0">
            <Box
                data-testid="Item-price"
                display="flex"
                gap="2px"
                alignItems="flex-end"
            >
                {!isGlobalCatalog && (
                    <Typography {...styles} fontSize="16px">
                        {`${itemPrice}${
                            itemPrice === 'Unavailable'
                                ? ''
                                : shouldShowUOM(uom)
                                ? '/UOM'
                                : ''
                        }`}
                    </Typography>
                )}
            </Box>
            {total ? (
                <Box
                    data-testid="Item-total-price"
                    display="flex"
                    gap="4px"
                    alignItems="center"
                >
                    <Typography {...styles} lineHeight="16px">
                        {t('Total')}:
                    </Typography>
                    <Typography
                        typography="openSans.subtitle1Medium"
                        fontWeight="bold"
                        color="black"
                        lineHeight="24px"
                    >
                        {getPrice(total)}
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
};
