import { memo, useCallback, useState } from 'react';
import { Box, Menu, Typography } from '@mui/material';
import { HelpMenuItems } from './HelpMenuItems';
import { useTranslation } from 'react-i18next';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';

export const HelpMenu = memo(() => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <>
            <Box
                display="flex"
                gap="8px"
                onClick={handleClick}
                sx={{
                    position: 'relative',
                    p: '12px',
                    pl: '24px',
                    ':hover': !open && {
                        bgcolor: 'action.hover',
                        cursor: 'pointer',
                    },
                    bgcolor: open && 'primary.main',
                    color: open && 'common.white',
                }}
            >
                <SupportOutlinedIcon
                    sx={{ color: open ? 'common.white' : 'action.active' }}
                />
                <Typography
                    typography="openSans.button"
                    lineHeight="24px"
                    fontWeight="400"
                    color={open ? 'common.white' : 'action.active'}
                >
                    {t('Help')}
                </Typography>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1))',
                            minWidth: '200px',
                            borderRadius: '6px',
                            mt: 1.5,
                        },
                    },
                }}
                sx={{
                    padding: 0,
                    margin: '-12px 0 0 2px',
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <HelpMenuItems />
            </Menu>
        </>
    );
});
